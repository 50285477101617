import {
  Directive,
  ElementRef,
  EventEmitter,
  OnDestroy,
  Output,
} from '@angular/core';

@Directive({
  standalone: true,
  selector: '[resizeObserver]',
})
export class FsResizeObserverDirective implements OnDestroy {
  @Output('resized') resized = new EventEmitter<DOMRectReadOnly>();

  private _resizeObserver: ResizeObserver;

  constructor(private element: ElementRef) {
    this._resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const { width, height } = entry.contentRect;
        this.resized.emit(entry.contentRect);
      }
    });

    this._resizeObserver.observe(this.element.nativeElement);
  }

  ngOnDestroy(): void {
    this._resizeObserver.unobserve(this.element.nativeElement);
  }
}
